const system = {
  name: "SYSTEMS",

  icon: "settings",
  children: [
    { name: "Employees", iconText: "SI", path: "/employees/list" },
    { name: "Company Holidays", iconText: "SI", path: "/holiday" },
    { name: "Approvals", iconText: "SI", path: "/approvals" },
    { name: "Configuration", iconText: "SI", path: "/setting" },

    // { name: "Roles", iconText: "SI", path: "/roles/roles" },
  ],
};
const adminLeaveManagement = {
  name: "LEAVES ",
  icon: "calendar_month",
  children: [
    { name: "Types", iconText: "SI", path: "/leave/management" },
    { name: "Applications", iconText: "SI", path: "/leave/tracker" },
    { name: "Adjustment", iconText: "SI", path: "/leave/adjustment" },
    { name: "Configuration", iconText: "SI", path: "/leave/setting" },
    { name: "Report", iconText: "SI", path: "/leave/leave-report" },
    {
      name: "Employees Leaves Balance",
      iconText: "SI",
      path: "/leave/balance",
    },
  ],
};

const employeeSystem = {
  name: "SYSTEMS",

  icon: "settings",
  children: [
    { name: "Company Holidays", iconText: "SI", path: "/holiday-calendar" },
  ],
};

const adminDashboard = [
  { name: "DASHBOARD", path: "/dashboard", icon: "dashboard" },
  { name: "ATTENDANCE", path: "/admin_attendance_list", icon: "fact_check" },
  { name: "LEAVES TRACKER", path: "/leaves", icon: "groups_2" },
  {
    name: "REVISION REQUESTS",
    icon: "manage_history",
    path: "/employee/requests",
  },
  { name: "REPORTS", icon: "summarize", path: "/employees/report" },
  {
    name: "ACTIVITY TRACKING",
    icon: "local_activity",
    path: "/employees/activity-tracking",
  },
  { name: "VISITORS", icon: "groups", path: "/visitor/list" },
  // { name: "TIMESHEET", icon: "feed", path: "/timesheet-list" },

  system,
  adminLeaveManagement,
];

const userDashboard = [
  {
    name: "DASHBOARD",
    path: "/employee-dashboard",
    icon: "dashboard",
  },
  {
    name: "ATTENDANCE",
    path: "/attendance",
    icon: "group",
  },
  {
    name: "LEAVES",
    path: "/employee-leave",
    icon: "group",
  },
  employeeSystem,
];

// export const getNavigationAsPerRole = () => {
//   const navBarData = JSON.parse(localStorage.getItem("role"));
//   let navArray = [...navBarData?.resourceData];
//   mainNavBarItem.forEach((ele) => {
//     navArray.push({
//       resourceName: ele.toUpperCase(),
//     });
//   });
//   console.log("newNavBarData-->", navArray);

//   // const navBarData = JSON.parse(localStorage.getItem("role"));
//   let nav;
//   if (navBarData != null) {
//     nav = navBarData?.resourceData?.map((role) => {
//       let obj = {
//         name: "",
//         path: "",
//         icon: "",
//       };
//       if (role.resourceName === "DASHBOARD") {
//         if (navBarData.roleName === "EMPLOYEE") {
//           obj["name"] = role.resourceName;
//           obj["icon"] = "dashboard";
//           obj["path"] = "/employee-dashboard";
//         } else {
//           obj["name"] = role.resourceName;
//           obj["icon"] = "dashboard";
//           obj["path"] = "/dashboard";
//         }
//       }

//       if (
//         role.resourceName === "ATTENDENCE" &&
//         navBarData.roleName === "SUPER-ADMIN"
//       ) {
//         obj["name"] = "ATTENDANCE";
//         obj["icon"] = "group";
//         obj["path"] = "/admin_attendance_list";
//       } else if (role.resourceName === "ATTENDENCE") {
//         obj["name"] = "ATTENDANCE";
//         obj["icon"] = "group";
//         obj["path"] = "/attendance";
//       }

//       if (role.resourceName === "LEAVE" && navBarData.roleName !== "EMPLOYEE") {
//         obj["name"] = role.resourceName;
//         obj["icon"] = "group";
//         obj["path"] = "/leaves";
//       } else {
//         if (
//           role.resourceName === "LEAVE" &&
//           navBarData.roleName === "EMPLOYEE"
//         ) {
//           return employeeSystem[0];
//         }
//       }

//       if (role.resourceName === "SYSTEMS") {
//         return system[0];
//       }

//       return obj;
//     });
//     // setNavigation(nav)
//     //  start for rearrange the array
//     if (nav) {
//       let index = nav.findIndex((obj) => obj.name === "DASHBOARD");
//       const toIndex = 0;
//       const element = nav.splice(index, 1)[0];
//       nav.splice(toIndex, 0, element);
//     }
//     return nav;
//   } else {
//     let nav = [
//       {
//         name: "DASHBOARD",
//         path: "/system-dashboard",
//         icon: "dashboard",
//       },
//       {
//         name: "COMPANY",
//         path: "/admin/company",
//         icon: "group",
//       },
//     ];
//     return nav;
//   }
// };
export const getNavigationAsPerRole = (role) => {
  if (role != null) {
    return role === "SUPER-ADMIN" ? adminDashboard : userDashboard;
  } else {
    return [
      {
        name: "DASHBOARD",
        path: "/system-dashboard",
        icon: "dashboard",
      },
      {
        name: "COMPANY",
        path: "/admin/company",
        icon: "group",
      },
    ];
  }
};
export const navigations = getNavigationAsPerRole();
