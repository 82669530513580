import { Button, styled } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));
const CustomButton = ({
  children,
  icon,
  onClick,
  style,
  variant,
  disabled,
  type,
}) => {
  return (
    <StyledButton
      variant={variant}
      startIcon={icon}
      onClick={onClick}
      style={style}
      disabled={disabled}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;
