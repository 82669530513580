import useAuth from "app/hooks/useAuth";

import { Navigate, useLocation } from "react-router-dom";

const EmployeeGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const tokan = localStorage.getItem("tokan");
  const role = JSON.parse(localStorage.getItem("role"));

  if (isAuthenticated) {
    if (role?.roleName === "EMPLOYEE") {
      return <>{children}</>;
    }
  }
  if (tokan) {
    if (role?.roleName === "EMPLOYEE") {
      return <>{children}</>;
    }
  }

  return <Navigate replace to="/login" state={{ from: pathname }} />;
};

export default EmployeeGuard;
