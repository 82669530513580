import {
  GetExportAllEmployeeList,
  getUserTodayAttendaceReport,
} from "app/services/Services";
import { generateErrorMessage } from "app/utils/GenerateMessage";
import { createContext } from "react";
import { useReducer } from "react";

const innitialState = {
  employeeList: [],
  employeeCheckIn: null,
  employeeCheckOut: null,
};
const reducer = (state, action) => {
  switch (action.type) {
    case "EMPLOYEE_LIST": {
      const { empList } = action.payload;

      return { ...state, employeeList: empList };
    }
    case "EMPLOYEE_CHECKIN_CHECKOUT": {
      const { checkIn, checkOut } = action.payload;

      return { ...state, employeeCheckIn: checkIn, employeeCheckOut: checkOut };
    }
    default:
      return state;
  }
};
const EmpListContext = createContext({
  ...innitialState,
});

export const EmpListProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, innitialState);
  const getEmployeeList = () => {
    GetExportAllEmployeeList()
      .then((res) => {
        if (res.status === 200) {
          if (res.data.data.length === 0) {
            dispatch({
              type: "EMPLOYEE_LIST",
              payload: { empList: [] },
            });
          } else {
            dispatch({
              type: "EMPLOYEE_LIST",
              payload: { empList: res?.data?.data },
            });
          }
        }
      })
      .catch((error) => {
        generateErrorMessage(`${error?.response?.data?.message || ""}`);
      });
  };
  const getEmployeeCheckinoutHistory = () => {
    getUserTodayAttendaceReport()
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: "EMPLOYEE_CHECKIN_CHECKOUT",
            payload: {
              checkIn: res?.data?.data?.checkInTime,
              checkOut: res?.data?.data?.checkOutTime,
            },
          });
        }
      })
      .catch((error) => {
        // Writing this logic in error block because when admin didnt checkin then this api showing error instead of empty data object;
        if (error?.response?.data?.message === "Attendance does not exist!") {
          dispatch({
            type: "EMPLOYEE_CHECKIN_CHECKOUT",
            payload: {
              checkIn: null,
              checkOut: null,
            },
          });
        }
      });
  };

  return (
    <EmpListContext.Provider
      value={{ ...state, getEmployeeList, getEmployeeCheckinoutHistory }}
    >
      {children}
    </EmpListContext.Provider>
  );
};

export default EmpListContext;
