import useAuth from "app/hooks/useAuth";

import { Navigate, useLocation } from "react-router-dom";

const CheckInOutGaurd = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const tokan = localStorage.getItem("tokan");

  if (isAuthenticated) {
    return <>{children}</>;
  }
  if (tokan) {
    return <>{children}</>;
  }

  return <Navigate replace to="/" state={{ from: pathname }} />;
};

export default CheckInOutGaurd;
