import React from "react";
import { Navigate, useLocation } from "react-router-dom";

const LogInLayout = (props) => {
  const location = useLocation();
  const token = localStorage.getItem("tokan");
  const role = JSON.parse(localStorage.getItem("role"));

  return (
    <div>
      {/* {token === null && App.navigate("/")} */}
      {token === null && <>{props.children}</>}

      {token && role?.roleName === "SUPER-ADMIN" && (
        <Navigate to={"/dashboard"} state={{ from: location }} replace />
      )}
      {token && role?.roleName === "EMPLOYEE" && (
        <Navigate
          to={"/employee-dashboard"}
          state={{ from: location }}
          replace
        />
      )}
      {token && role === null && (
        <Navigate to={"/system-dashboard"} state={{ from: location }} replace />
      )}
      {/* 
      {!!token ? <Navigate
          to={"/auth"}
          state={{ from: location }} 
          replace
        /> : <>{props.children}</>} */}
    </div>
  );
};

export default LogInLayout;
