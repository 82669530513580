import { toast } from "react-toastify";

export const generateSuccessMessage = (message) => {
  toast.success(`${message}`, {
    position: toast.POSITION.TOP_RIGHT,
    toastId: "successful1",
  });
};
export const generateErrorMessage = (message) => {
  toast.error(`${message}`, {
    position: toast.POSITION.TOP_RIGHT,
    toastId: "error",
  });
};
export const generateWarningMessage = (message) => {
  toast.warning(`${message}`, {
    position: toast.POSITION.TOP_RIGHT,
    toastId: "error2",
  });
};
