import useAuth from "app/hooks/useAuth";

import { Navigate, useLocation } from "react-router-dom";

const CompanyGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const tokan = localStorage.getItem("tokan");
  const role = JSON.parse(localStorage.getItem("role"));

  if (isAuthenticated) {
    if (role === null) {
      return <>{children}</>;
    }
  }
  if (tokan) {
    if (role === null) {
      return <>{children}</>;
    }
  }

  return <Navigate replace to="/admin/login" state={{ from: pathname }} />;
};
export default CompanyGuard;
