import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  styled,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { SimpleCard } from "app/components";
import { Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CustomButton from "app/components/Button";
import SendIcon from "@mui/icons-material/Send";
import { useEffect } from "react";
import { useState } from "react";
import {
  addLeaveType,
  getAccrualPeriod,
  getAccrualReset,
  getAccrualTime,
  getLeaveCategoryType,
  updateLeaveType,
} from "app/services/Services";
import {
  generateErrorMessage,
  generateSuccessMessage,
} from "app/utils/GenerateMessage";
import { dateCompare, nameConverter2 } from "app/utils/utils";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
// import {  FormHelperText } from "@material-ui/core";

const Container = styled("div")(({ theme }) => ({
  margin: "30px",
  [theme.breakpoints.down("sm")]: { margin: "16px" },
  "& .breadcrumb": {
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
  },
}));
const Title = styled("span")(() => ({
  fontSize: "1.2rem",
  fontWeight: "600",
  textTransform: "capitalize",
}));
const CardHeader = styled(Box)(() => ({
  display: "flex",
  paddingLeft: "24px",
  paddingRight: "24px",
  marginBottom: "12px",
  alignItems: "center",
  justifyContent: "space-between",
}));
const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));
const StyledTextField = styled(TextField)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const AutoComplete = styled(Autocomplete)(() => ({
  width: "100%",
  marginBottom: "16px",
}));
const carryForward = [{ label: "Yes" }, { label: "No" }];

const validationSchema = Yup.object().shape({
  leave: Yup.string().trim().required("Field is required!"),
  accrualTime: Yup.string().trim().required("Field is required!"),
  leaveType: Yup.string().trim().required("Field is required!"),
  accrualPeriod: Yup.string().trim().required("Field is required!"),
  accrualReset: Yup.string().trim().required("Field is required!"),
  carryForword: Yup.string().trim().required("Field is required!"),
  accrualCount: Yup.number().required("Field is required!"),
  carryForwardMaxLimit: Yup.number().required("Field is required!"),
  validForm: Yup.date()
    .nullable()
    .typeError("Leave Valid To date value must be in dd/MM/yyyy format")
    .required("Field is required!"),

  validTo: Yup.date()
    .nullable()
    .typeError("Leave Valid To date value must be in dd/MM/yyyy format")
    .test({
      message: "validTo must be greater than validForm",
      test: (value, context) => {
        if (
          (value !== null &&
            dateCompare(value) > dateCompare(context?.parent?.validForm)) ||
          dateCompare(value) === dateCompare(context?.parent?.validForm)
        ) {
          return true;
        } else {
          if (value === null) {
            return true;
          }
          return false;
        }
      },
    })
    .notRequired(),

  carryForwardMaxLimit: Yup.number().when(
    "carryForword",
    (carryForword, schema) => {
      if (carryForword && carryForword[0] === "true") {
        return schema
          .min(1, "Carry forward limit must be greater than 0")
          .required("Field is required!");
      } else {
        return schema
          .lessThan(1, "Carry forward limit must be equal to 0")
          .required("Field is required!");
      }
    }
  ),
});
let formInnitialData = {
  leave: "",
  accrualTime: "",
  carryForwardMaxLimit: "",
  leaveType: "",
  accrualCount: "",
  validForm: null,
  accrualPeriod: "",
  accrualReset: "",
  carryForword: "",
  validTo: null,
};
const AddLeaveType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const { leaveTypeDetail } = state || {};

  const [leaveType, setLeaveType] = useState([]);
  const [accrualPeriod, setAccrualPeriod] = useState([]);
  const [accrualTime, setAccrualTime] = useState([]);
  const [accrualReset, setAccrualReset] = useState([]);
  const [loader, setLoader] = useState(false);
  // const [isCarryForward, setIsCarryForward] = useState(
  //   leaveTypeDetail?.isCarryForword
  //     ? leaveTypeDetail?.isCarryForword
  //       ? "Yes"
  //       : "No"
  //     : ""
  // );
  const [isCarryForward, setIsCarryForward] = useState(null);
  const [innitialValue, setInnitialValue] = useState(formInnitialData);
  // !!leaveTypeDetail?.isCarryForword ? "Yes" : "No"

  const getLeaveData = (API, setState) => {
    setLoader(true);
    API()
      .then((res) => {
        setLoader(false);
        if (res) {
          if (res.status === 200) {
            setState(res?.data?.data);
          }
        } else {
          generateErrorMessage(`${"Something went wrong."}`);
        }
      })
      .catch((error) => {
        setLoader(false);
        generateErrorMessage(`${error?.response?.data?.message || ""}`);
      });
  };
  useEffect(() => {
    getLeaveData(getLeaveCategoryType, setLeaveType);
  }, []);
  useEffect(() => {
    getLeaveData(getAccrualPeriod, setAccrualPeriod);
  }, []);
  useEffect(() => {
    getLeaveData(getAccrualTime, setAccrualTime);
  }, []);
  useEffect(() => {
    getLeaveData(getAccrualReset, setAccrualReset);
  }, []);

  const handleLeaveTypeSubmit = (values, API) => {
    setLoader(true);
    if (values) {
      let body = {
        name: nameConverter2(values?.leave),
        type: values?.leaveType,
        accrualPeriodType: values?.accrualPeriod,
        accrualTimeType: values?.accrualTime,
        accrualCount: values?.accrualCount,
        accrualReset: values?.accrualReset,
        isCarryForword: values?.carryForword,
        carryForwordMaxLimit: values?.carryForwardMaxLimit,
        validFrom: moment(values?.validForm).format("yyyy-MM-DD") || "",
        validTo: values?.validTo ? moment(values.validTo).format("yyyy-MM-DD") : "",
      };
      state && (body["leaveTypeId"] = leaveTypeDetail?.leaveTypeId);
      API(body)
        .then((res) => {
          if (res) {
            setLoader(false);
            if (res.status === 200) {
              setLoader(false);
              generateSuccessMessage(res?.data?.message || "");
              navigate(-1);
            }
          } else {
            setLoader(false);
            generateErrorMessage(`${"Something went wrong."}`);
          }
        })
        .catch((error) => {
          setLoader(false);
          generateErrorMessage(error?.response?.data?.message || "");
        });
    }
  };
  const handleNumberInput = (e, setFieldValue, fieldName, setFieldError) => {
    const { value } = e?.target || {};

    const regex = /^\d*\.?\d*$/; // Updated regex to allow decimal or float
    const limitNum = /^[0-9]{1,11}$/; // Adjusted to allow up to 11 characters
    if (!regex.test(value)) {
      setFieldError(fieldName, "Invalid number format");
      return;
    }
    if (value.length > 11) {
      setFieldError(fieldName, "Must not be greater than 11 characters");
      return;
    }
    setFieldValue(fieldName, value);
  };

  useEffect(() => {
    if (state) {
      setInnitialValue({
        leave: leaveTypeDetail?.name,
        accrualTime: leaveTypeDetail?.accrualTimeType,
        carryForwardMaxLimit: leaveTypeDetail?.carryForwordMaxLimit,
        leaveType: leaveTypeDetail?.type,
        accrualCount: leaveTypeDetail?.accrualCount,
        validForm: leaveTypeDetail?.validFrom,
        accrualPeriod: leaveTypeDetail?.accrualPeriodType,
        accrualReset: leaveTypeDetail?.accrualReset,
        carryForword: leaveTypeDetail?.isCarryForword,
        validTo: leaveTypeDetail?.validTo,
      });
      setIsCarryForward(leaveTypeDetail?.isCarryForword);
    }
  }, [state]);
  return (
    <Container>
      <SimpleCard title="">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: { xs: "column", sm: "column", md: "row", lg: "row" },
          }}
        >
          {/* <h4 className="infoHeaders">Employee Details:</h4> */}
          <Title>{state ? "Edit" : "Add"}</Title>

          <CardHeader>
            <StyledButton
              variant="contained"
              onClick={() => navigate(-1)}
              color="primary"
            >
              Back
            </StyledButton>
          </CardHeader>
        </Box>
        <Formik
          initialValues={innitialValue}
          enableReinitialize={true}
          onSubmit={(values) => {
            state
              ? handleLeaveTypeSubmit(values, updateLeaveType)
              : handleLeaveTypeSubmit(values, addLeaveType);
          }}
          validationSchema={validationSchema}
        >
          {({
            handleChange,
            values,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
            setFieldError,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
                  <StyledTextField
                    name="leave"
                    variant="standard"
                    label="Name"
                    onChange={handleChange}
                    autoComplete="off"
                    onBlur={handleBlur}
                    value={values?.leave || ""}
                    helperText={touched.leave && errors.leave}
                    error={touched.leave && errors.leave ? true : false}
                  />

                  <AutoComplete
                    value={values?.accrualTime}
                    options={accrualTime}
                    getOptionLabel={(list) => {
                      if (list.name) {
                        return list.name;
                      } else {
                        return list;
                      }
                    }}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={`${option.name}`}>
                          {option.name}
                        </li>
                      );
                    }}
                    onChange={(e, val) => {
                      if (val) {
                        const { value } = val;
                        handleChange({
                          ...e,
                          target: { name: "accrualTime", value: value || "" },
                        });
                      } else {
                        handleChange({
                          ...e,
                          target: { name: "accrualTime", value: "" },
                        });
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Accrual Time"
                          variant="standard"
                          name="accrualTime"
                          fullWidth
                          helperText={touched.accrualTime && errors.accrualTime}
                          error={
                            touched.accrualTime && errors.accrualTime
                              ? true
                              : false
                          }
                        />
                      );
                    }}
                  />

                  <AutoComplete
                    value={
                      isCarryForward !== null
                        ? isCarryForward
                          ? "Yes"
                          : "No"
                        : ""
                    }
                    options={carryForward}
                    getOptionLabel={(option) => {
                      if (option.label) {
                        return option.label;
                      } else {
                        return option;
                      }
                    }}
                    onChange={(e, val) => {
                      if (val) {
                        const { label } = val;
                        let isCarryForwar;
                        if (label === "Yes") {
                          isCarryForwar = true;
                        } else if (label === "No") {
                          isCarryForwar = false;
                        }
                        handleChange({
                          ...e,
                          target: {
                            name: "carryForword",
                            value: isCarryForwar,
                          },
                        });
                        setIsCarryForward(isCarryForwar);
                      } else {
                        handleChange({
                          ...e,
                          target: { name: "carryForword", value: "" },
                        });
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          name="carryForword"
                          helperText={
                            touched.carryForword && errors.carryForword
                          }
                          error={
                            touched.carryForword && errors.carryForword
                              ? true
                              : false
                          }
                          label="Leave Carry Forword"
                          variant="standard"
                          fullWidth
                        />
                      );
                    }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      value={values.validTo ? new Date(values.validTo) : null}
                      // value={formatDate(values?.validTo)}
                      views={["year", "month", "day"]}
                      label="Leave Valid To"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        // const formattedDate = formatDate(e);
                        handleChange({
                          target: {
                            name: "validTo",
                            value: e,
                          },
                        });
                      }}
                      // onChange={(date) => {
                      //   if (date instanceof Date && !isNaN(date.getTime())) {
                      //     handleChange({
                      //       target: {
                      //         name: "validTo",
                      //         value: `${date.getDate()}-${
                      //           date.getMonth() + 1
                      //         }-${date.getFullYear()}`,
                      //       },
                      //     });
                      //   } else {
                      //     handleChange({
                      //       target: {
                      //         name: "validTo",
                      //         value: "",
                      //       },
                      //     });
                      //   }
                      // }}
                      minDate={
                        values.validForm ? new Date(values.validForm) : null
                      }
                      slotProps={{
                        textField: {
                          variant: "standard",
                          style: { width: "100%" },

                          helperText: errors.validTo,
                          error: errors.validTo ? true : false,
                        },
                      }}
                      // renderInput={(props) => (
                      // <TextField
                      //   {...props}
                      //   variant="standard"
                      //   style={{ width: "100%" }}
                      //   helperText={errors.validTo}
                      //   error={Boolean(errors.validTo)}
                      // />
                      // )}
                      // minDate={new Date(values?.validForm)}
                    />
                  </LocalizationProvider>
                  {values?.validTo && (
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        handleChange({
                          target: {
                            name: "validTo",
                            value: null,
                          },
                        });
                      }}
                      sx={{
                        position: "absolute",
                        pt: "19px",
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
                  <AutoComplete
                    value={values?.leaveType}
                    options={leaveType}
                    getOptionLabel={(list) => {
                      if (list.name) {
                        return list.name;
                      } else {
                        return list;
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, val) => {
                      if (val) {
                        const { value } = val;
                        handleChange({
                          ...e,
                          target: { name: "leaveType", value: value || "" },
                        });
                      } else {
                        handleChange({
                          ...e,
                          target: { name: "leaveType", value: "" },
                        });
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          label="Leave Type"
                          variant="standard"
                          name="leaveType"
                          fullWidth
                          helperText={touched.leaveType && errors.leaveType}
                          error={
                            touched.leaveType && errors.leaveType ? true : false
                          }
                        />
                      );
                    }}
                  />

                  <StyledTextField
                    name="accrualCount"
                    variant="standard"
                    label="Accrual Count"
                    onChange={(e) => {
                      handleNumberInput(
                        e,
                        setFieldValue,
                        "accrualCount",
                        setFieldError
                      );
                    }}
                    autoComplete="off"
                    onBlur={handleBlur}
                    value={values?.accrualCount}
                    helperText={touched.accrualCount && errors.accrualCount}
                    error={
                      touched.accrualCount && errors.accrualCount ? true : false
                    }
                  />
                  <StyledTextField
                    name="carryForwardMaxLimit"
                    variant="standard"
                    label="Leave Carry Forward Limit"
                    onChange={(e) => {
                      handleNumberInput(
                        e,
                        setFieldValue,
                        "carryForwardMaxLimit",
                        setFieldError
                      );
                    }}
                    autoComplete="off"
                    onBlur={handleBlur}
                    value={values?.carryForwardMaxLimit}
                    helperText={
                      touched.carryForwardMaxLimit &&
                      errors.carryForwardMaxLimit
                    }
                    error={
                      touched.carryForwardMaxLimit &&
                      errors.carryForwardMaxLimit
                        ? true
                        : false
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 0 }}>
                  <AutoComplete
                    value={values?.accrualPeriod}
                    options={accrualPeriod}
                    getOptionLabel={(list) => {
                      if (list.name) {
                        return list.name;
                      } else {
                        return list;
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, val) => {
                      if (val) {
                        const { value } = val;
                        handleChange({
                          ...e,
                          target: { name: "accrualPeriod", value: value || "" },
                        });
                      } else {
                        handleChange({
                          ...e,
                          target: { name: "accrualPeriod", value: "" },
                        });
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          name="accrualPeriod"
                          helperText={
                            touched.accrualPeriod && errors.accrualPeriod
                          }
                          error={
                            touched.accrualPeriod && errors.accrualPeriod
                              ? true
                              : false
                          }
                          label="Accrual Period"
                          variant="standard"
                          fullWidth
                        />
                      );
                    }}
                  />
                  <AutoComplete
                    value={values?.accrualReset}
                    options={accrualReset}
                    getOptionLabel={(list) => {
                      if (list.name) {
                        return list.name;
                      } else {
                        return list;
                      }
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, val) => {
                      if (val) {
                        const { value } = val;
                        handleChange({
                          ...e,
                          target: { name: "accrualReset", value: value || "" },
                        });
                      } else {
                        handleChange({
                          ...e,
                          target: { name: "accrualReset", value: "" },
                        });
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          name="accrualReset"
                          helperText={
                            touched.accrualReset && errors.accrualReset
                          }
                          error={
                            touched.accrualReset && errors.accrualReset
                              ? true
                              : false
                          }
                          label="Accrual Reset"
                          variant="standard"
                          fullWidth
                        />
                      );
                    }}
                  />
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      // value={formatDate(values?.validForm)}
                      value={
                        values?.validForm ? new Date(values?.validForm) : null
                      }
                      views={["year", "month", "day"]}
                      label="Leave Valid From"
                      name="validForm"
                      variant="standard"
                      onChange={(e) => {
                        // const formattedDate = formatDate(e);

                        handleChange({
                          target: {
                            name: "validForm",
                            value: e,
                          },
                        });
                      }}
                      slotProps={{
                        textField: {
                          variant: "standard",
                          style: { width: "100%" },
                          helperText: errors.validForm,
                          error: errors.validForm ? true : false,
                        },
                      }}
                      // renderInput={(params) => (
                      //   <>
                      //     <TextField
                      //       {...params}
                      //       variant="standard"
                      //       style={{ width: "100%" }}
                      //       error={Boolean(
                      //         touched.validForm && errors.validForm
                      //       )}
                      //     />
                      //     {touched.validForm && errors.validForm && (
                      //       <FormHelperText error>
                      //         {errors.validForm}
                      //       </FormHelperText>
                      //     )}
                      //   </>
                      // )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <CustomButton
                variant="contained"
                type="submit"
                icon={<SendIcon />}
                style={{ float: "right", marginTop: "25px" }}
              >
                {state ? "Update" : "Submit"}
              </CustomButton>
            </form>
          )}
        </Formik>
      </SimpleCard>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

export default AddLeaveType;
